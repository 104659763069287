<!-- @format -->

<template>
	<layout-default-new>
		<div>
			<b-tag class="is-success margin-bottom-20" size="is-large">
				Index our dispatches - calculate stock transactions to out
			</b-tag>
		</div>
		<div>
			<form id="formSetInventoryValues">
				<vue-form-generator
					:schema="schemaInventoryValues"
					:model="modelInventoryValues"
					:options="formOptionsInventoryValues"
				>
				</vue-form-generator>
			</form>
			<br />
			<div>
				<b-field label="Select beginning date">
					<b-datepicker
						v-model="selectedBeginningDate"
						:first-day-of-week="firstDayOfWeek"
						:show-week-number="showWeekNumber"
						:locale="locale"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
				<b-field label="Select ending date">
					<b-datepicker
						v-model="selectedEndingDate"
						:show-week-number="showWeekNumber"
						:first-day-of-week="firstDayOfWeek"
						:locale="locale"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
			</div>
			<b-button class="is-primary margin-top-20" type="submit" @click="onSubmit"
				>Save Inventory Variables & Continue
			</b-button>
			<div class="text-red margin-top-5 text-bold">
				.... Set beginning date and ending date for indexing
			</div>
		</div>
	</layout-default-new>
</template>

<script>
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'
import { onMounted, reactive, ref, watchEffect } from '@vue/composition-api'
import Router from '@/router'
import useInventoryState from './useInventoryState'
import Store from '@/store'
import dateformat from 'dateformat'
import { masks } from 'dateformat'

export default {
	name: 'SetInventoryVariablesValues',
	setup() {
		const parameters = reactive({
			beginningDate: '',
			endingDate: '',
		})
		masks.ve = 'yyyy-mm-dd'
		// * ---------------------------------------------
		const selectedBeginningDate = ref(new Date())
		watchEffect(() => {
			parameters.beginningDate = dateformat(selectedBeginningDate.value, 've')
			console.log('parameters.beginningDate', parameters.beginningDate)
		})
		const selectedEndingDate = ref(new Date())
		watchEffect(() => {
			parameters.endingDate = dateformat(selectedEndingDate.value, 've')
			console.log('parameters.endingDate', parameters.endingDate)
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		const firstDayOfWeek = ref(1)
		onMounted(() => {
			console.log('mounted!')
			modelInventoryValues.ourCompany = Store.getters.getUserCurrentCompany
		})
		const { state } = useInventoryState()
		const storeVariables = () => {
			console.log('parameters.beginningDate', parameters.beginningDate)
			console.log('parameters.endingDate', parameters.endingDate)
			return new Promise((resolve) => {
				state.ourCompany = Store.getters.getUserCurrentCompany
				// dispatchNumber.value = modelInventoryValues.dispatchNumber
				state.indexBeginningDate = parameters.beginningDate
				state.indexEndingDate = parameters.endingDate
				resolve('Done')
			})
		}
		const onSubmit = () => {
			storeVariables().then(() => Router.push({ name: 'GetInventoryValues' }))
		}
		const modelInventoryValues = reactive({
			ourCompany: '',
		})
		const schemaInventoryValues = reactive({
			groups: [
				{
					legend: 'Stock Item Inventory Values',
					id: 'headers',
					featured: true,
					fields: [
						{
							type: 'input',
							inputType: 'text',
							label: 'Our Company',
							model: 'ourCompany',
							id: 'company_name',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
					],
				},
			],
		})
		const formOptionsInventoryValues = reactive({
			validateAfterLoad: true,
			validateAfterChanged: true,
			validateAsync: true,
			fieldIdPrefix: 'inventory-values',
		})
		// --------------------------------------------
		return {
			// onFormValidated,
			// toggleAddButton,
			onSubmit,
			modelInventoryValues,
			schemaInventoryValues,
			formOptionsInventoryValues,
			// ----------------------------
			selectedBeginningDate,
			selectedEndingDate,
			showWeekNumber,
			locale,
			firstDayOfWeek,
		}
	},
}
</script>

<style scoped></style>
